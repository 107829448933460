import React from "react"
import PropTypes from "prop-types"
import GoogleMapReact from "google-map-react"
import locationMarker from "../images/marker.png"
import mapStyles from "../utils/mapStyles.json"
import { graphql, useStaticQuery } from "gatsby"

const createMapOptions = () => {
  return {
    panControl: true,
    mapTypeControl: false,
    zoomControl: false,
    scrollwheel: false,
    styles: mapStyles,
  }
}

const Marker = () => {
  return (
    <div style={{ width: "200px" }}>
      <img src={locationMarker} alt="Marker" />
    </div>
  )
}
const GoogleMap = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          googleMapsApiKey
        }
      }
    }
  `)
  return (
    // Important! Always set the container height explicitly
    <div className="googleMap">
      <GoogleMapReact
        bootstrapURLKeys={{ key: data.site.siteMetadata.googleMapsApiKey }}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
        yesIWantToUseGoogleMapApiInternals
        layerTypes={["TrafficLayer", "TransitLayer"]}
        options={createMapOptions}
      >
        <Marker lat={props.center.lat} lng={props.center.lng} />
      </GoogleMapReact>
    </div>
  )
}

GoogleMap.defaultProps = {
  center: {
    lat: -16.1404,
    lng: 33.612524,
  },
  zoom: 17,
}

GoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
}),
  zoom: PropTypes.number
}

export default GoogleMap
