import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import { FaWhatsapp } from 'react-icons/fa'

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import ContactForm from "../elements/contact_form"
import GoogleMap from "../elements/google_map"

const ContactPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "contact" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
        description="Need to get in touch? We at New Macau Casino are always happy to help! Send us a message and we'll be in touch shortly."        
      />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "contact_title" })}
        sliderSubtitle={intl.formatMessage({ id: "contact_subtitle" })}
        objectPosition="10% 50%"
      />
      <div className="contactPage">
        <div className="contactPage__item contactPage__item1">
          <h1>{intl.formatMessage({ id: "contact_us" })}</h1>
          <div>
            <h4>{intl.formatMessage({ id: "general" })}</h4>
            <p>{intl.formatMessage({ id: "general_physical" })}</p>
          </div>
          <div>
            <h4>{intl.formatMessage({ id: "promotions" })}</h4>
            <p>{intl.formatMessage({ id: "promotions_physical" })}</p>
          </div>
          <div>
            <h4>{intl.formatMessage({ id: "telephone" })}</h4>
            <p>{intl.formatMessage({ id: "telephone_physical" })}</p>
            <p><span className="contact__whatsApp"><FaWhatsapp /></span>{intl.formatMessage({ id: "telephone_whatsApp" })}</p>
          </div>
          <div>
            <h4>{intl.formatMessage({ id: "address" })}</h4>
            <p>{intl.formatMessage({ id: "address_physical" })}</p>
          </div>
        </div>
        <div className="contactPage__item contactPage__item2">
          <ContactForm />
        </div>
        <div className="contactPage__item contactPage__item3">
          <GoogleMap />
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(ContactPage)
